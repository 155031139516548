'use client'

import Spline from '@splinetool/react-spline'
import { Suspense } from 'react'

import { cn } from '../../utils'

import SplineErrorBoundary from './SplineErrorBoundary'

interface SplineViewerProps {
  readonly scene: string
  readonly className?: string
}

export function SplineViewer({ className, scene }: SplineViewerProps) {
  return (
    <SplineErrorBoundary>
      <Suspense fallback={null}>
        <Spline
          scene={scene}
          className={cn('sm:hidden lg:block opacity-25 lg:opacity-100 w-full h-full', className)}
          style={{
            aspectRatio: '5 / 3',
            objectFit: 'contain',
            objectPosition: 'center right',
            pointerEvents: 'auto',
            position: 'absolute',
          }}
          onWheel={(e: React.WheelEvent) => {
            e.stopPropagation()
            window.scrollBy(0, e.deltaY)
          }}
        />
      </Suspense>
    </SplineErrorBoundary>
  )
}
