'use client'

import { AnimatedDivider } from '@ethena/shared-ui/src/components/AnimatedDivider'

import { INVESTORS } from '../constants'

import { Investor } from './Investor'
import { InvestorsRow } from './InvestorRow'

export const InvestorsBox = () => {
  return (
    <div className={`flex flex-col gap-6 w-full justify-center sm:px-10 md:px-16 lg:px-20 xl:px-[100px] pb-6 lg:pb-4`}>
      <span className="font-normal text-base text-white">Backed By</span>

      <AnimatedDivider />

      <InvestorsRow>
        {INVESTORS.map(({ image, link }) => (
          <Investor key={link} image={image()} link={link} />
        ))}
      </InvestorsRow>
    </div>
  )
}
