import * as React from 'react'

interface PrimaryButtonProps {
  text: string
  size?: 'small' | 'medium' | 'large'
  width?: 'normal' | 'full'
  type?: 'normal'
  onClick?: () => void
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  onClick,
  size = 'medium',
  text,
  type = 'normal',
  width = 'normal',
}) => {
  const sizeClasses = {
    large: 'px-12 py-2 text-base h-8 font-bold text-lg',
    medium: 'px-4 py-2 text-base h-8 font-medium',
    small: 'px-3 py-1.5 text-[12px] font-medium',
  }

  const dimensions = {
    large: {
      height: {
        btnInner: 'h-[50px]',
        btnOuter: 'h-[52px]',
      },
    },
    medium: {
      height: {
        btnInner: 'h-[32px]',
        btnOuter: 'h-[34px]',
      },
    },
    small: {
      height: {
        btnInner: 'h-[22px]',
        btnOuter: 'h-[24px]',
      },
    },
  }

  const widthClasses = {
    full: 'w-full',
    normal: 'w-auto',
  }

  const gradientClasses = {
    normal: 'bg-gradient-to-r from-[#88b4f5] via-[#88b4f5]/20 to-[#88b4f5]',
  }

  return (
    <button
      onClick={onClick}
      className={`
        ${widthClasses[width]}
        flex justify-center items-center
        gap-0
        [&>*:nth-child(1)]:grow
        ${dimensions[size].height.btnOuter}
        relative
        primary-button
      `}
    >
      <span
        className={`
        flex justify-center items-center
        ${gradientClasses[type]}
        ${dimensions[size].height.btnOuter}
        z-10
        button-middle-${size}-background
        button-middle-${type}
      `}
      >
        <span
          className={`
          button-middle-${size}-foreground text-white bg-black 
          ${dimensions[size].height.btnInner}
          flex items-center justify-center
          ${sizeClasses[size]}
          w-full
          whitespace-nowrap
          uppercase
        `}
        >
          {text}
        </span>
      </span>
      <span
        className={`
        button-primary-shadow-container
      `}
      >
        <span
          className={`
        button-primary-shadow-${type}
        button-primary-shadow
      `}
        />
      </span>
    </button>
  )
}

export default PrimaryButton
