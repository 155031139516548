import Image from 'next/image'

export const INVESTORS = [
  {
    image: () => (
      <Image src="/investors/dragonfly.svg" alt="Dragonfly" width={24} height={24} className="w-auto h-auto" />
    ),
    link: 'https://www.dragonfly.xyz/',
  },
  {
    image: () => (
      <Image src="/investors/binance-labs.svg" alt="Binance" width={24} height={24} className="w-auto h-auto" />
    ),
    link: 'https://www.binance.com/',
  },
  {
    image: () => <Image src="/investors/bybit.svg" alt="Bybit" width={24} height={24} className="w-auto h-auto" />,
    link: 'https://www.bybit.com/',
  },
  {
    image: () => <Image src="/investors/okx.svg" alt="OKX" width={24} height={24} className="w-auto h-auto" />,
    link: 'https://www.okx.com/',
  },
  {
    image: () => <Image src="/investors/deribit.svg" alt="Deribit" width={24} height={24} className="w-auto h-auto" />,
    link: 'https://www.deribit.com/',
  },
  {
    image: () => (
      <Image src="/investors/fidelity.svg" alt="Fidelity" width={24} height={24} className="w-auto h-auto" />
    ),
    link: 'https://www.fidelity.com/',
  },
  {
    image: () => (
      <Image
        src="/investors/franklintempleton.svg"
        alt="Franklin Templeton"
        width={24}
        height={24}
        className="w-auto h-auto"
      />
    ),
    link: 'https://www.franklintempleton.com/',
  },
  {
    image: () => (
      <Image src="/investors/wintermute.svg" alt="Wintermute" width={24} height={24} className="w-auto h-auto" />
    ),
    link: 'https://www.wintermute.com/',
  },
] satisfies {
  image: () => React.ReactElement
  link: string
}[]
