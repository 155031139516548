import * as Sentry from '@sentry/nextjs'
import { Component, ReactNode } from 'react'

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

class SplineErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    // capture message as we are aware of the issue and want to quietly track number of occurrences
    Sentry.captureMessage(`Spline error boundary caught an error: ${error}`, 'warning')
  }

  render() {
    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}

export default SplineErrorBoundary
