'use client'

import { EthenaTooltip } from '@ethena/shared-ui/src/components/EthenaTooltip'
import Placeholder from '@ethena/shared-ui/src/components/Placeholder'
import { StatTooltip } from '@ethena/shared-ui/src/components/StatTooltip'
import { StyledALink } from '@ethena/shared-ui/src/components/StyledALink'
import { DAPP_URL, GITBOOK_URL } from '@ethena/shared-ui/src/constants/url'
import { useSusdeYieldTooltipData } from '@ethena/shared-ui/src/hooks/useSusdeYieldTooltipData'
import { cn } from '@ethena/shared-ui/utils'
import Image from 'next/image'
import Link from 'next/link'

import { useState } from 'react'

import { IS_GERMAN } from '@/constants'
import useUserStore from '@/store/user-store'

import PrimaryButton from './PrimaryButton'

export const Hero = () => {
  const { hasVisitedHomepage, setHasVisitedHomepage } = useUserStore()
  const { tooltip, yieldApy, yieldStr } = useSusdeYieldTooltipData()
  const [hasTyped, setHasTyped] = useState(false)

  return (
    <div className="w-fit z-10 my-16 md:mx-10 lg:mx-[100px] self-start animate-in slide-in-from-bottom-8 duration-700 mx-2">
      <div className="flex-1 flex flex-col justify-center gap-5">
        <h1
          aria-label={IS_GERMAN ? 'USDe: ENABLING INTERNET MONEY' : 'ENABLING INTERNET MONEY'}
          className="min-h-[76px] xxs-max:min-h-[113px] sm:min-h-[125px] md:min-h-[144px] text-white text-[2.5rem] sm:text-[3rem] md:text-[4rem] leading-tight md:leading-[4.5rem] font-semibold"
        >
          {!hasVisitedHomepage || !hasTyped ? (
            <div>
              <span className={cn(IS_GERMAN ? 'typewriter-german-first-line' : 'typewriter-first-line')}></span>
              <br />
              <span
                className="typewriter-second-line"
                onAnimationEnd={() => {
                  setHasTyped(true)
                  setHasVisitedHomepage(true)
                }}
              ></span>
            </div>
          ) : (
            <div>
              <span>{IS_GERMAN ? 'USDe: ENABLING' : 'ENABLING'}</span>
              <br />
              <span>INTERNET MONEY_</span>
            </div>
          )}
        </h1>
        <h5 className="text-neutrals-400 sm:w-full text-lg sm:text-xl md:text-[25px] font-extralight flex flex-col md:flex-row md:items-center justify-between">
          <span className={cn(!IS_GERMAN ? 'pr-[1.3rem]' : '')}>
            {`Synthetic Dollar ${!IS_GERMAN ? 'with Internet Native Yield: ' : ''}`}
          </span>
          {!IS_GERMAN && (
            <span className="text-cblue-500 font-bold block md:inline-block w-fit mt-4 md:mt-0 yield-box flex items-center pt-1 pb-1 px-4">
              <Placeholder isLoading={!yieldStr} classes="inline-block mr-2 mt-1 w-[2.625rem] h-[1.125rem]">
                <span className="inline-block pr-2">{yieldStr}</span>
              </Placeholder>
              <EthenaTooltip
                width="w-[320px] sm:w-[400px]"
                padding="p-3"
                content={<StatTooltip title={`sUSDe APY: ${yieldApy ?? 'loading...'}`} description={tooltip} />}
                side={{ base: 'right', sm: 'top' }}
              >
                <Image
                  src="/shared/info-round.svg"
                  alt="Info icon"
                  width={14}
                  height={14}
                  className="opacity-60 md:mb-0.5"
                />
              </EthenaTooltip>
            </span>
          )}
        </h5>
        {!IS_GERMAN && (
          <Link href={DAPP_URL + '/buy'} className="w-fit mt-2" target="_blank">
            <PrimaryButton text="Launch App" />
          </Link>
        )}
        {IS_GERMAN && (
          <>
            <div>
              <h5 className="w-full sm:w-[420px] mb-1 underline">Imprint</h5>
              <p className="text-xs w-full sm:w-[420px]">
                <span>Ethena GmbH, Friedrich-Ebert-Anlage 49, 60308 Frankfurt am Main</span>
                <br />
                <span>Email: finance@ethena.gmbh</span>
                <br />
                <span>Commercial Register: District Court of Frankfurt, HRB 136862</span>
                <br />
                <span>
                  Supervisory Authority: Bundesanstalt für Finanzdienstleistungsaufsicht, Marie-Curie-Straße 24-28,
                  60439 Frankfurt am Main
                </span>
                <br />
                <span>Managing Director: Guy Young and Torsten Luettich</span>
                <br />
                <span>Responsible for Content: Torsten Luettich</span>
                <br />
                <span>
                  The European Commission provides a platform for online dispute resolution (OS) at
                  http://ec.europa.eu/consumers/odr. We are not obliged nor willing to participate in an online dispute
                  resolution procedure.
                </span>
                <br />
                <br />
              </p>
            </div>
            <div>
              <h5 className="w-full sm:w-[420px] mb-1 underline">Impressum</h5>
              <p className="text-xs w-full sm:w-[420px]">
                <span>Ethena GmbH, Friedrich-Ebert-Anlage 49, 60308 Frankfurt am Main</span>
                <br />
                <span>Email: finance@ethena.gmbh</span>
                <br />
                <span>Handelsregister: AG Frankfurt am Main, HRB 136862</span>
                <br />
                <span>
                  Regulierunsbehörde: Bundesanstalt für Finanzdienstleistungsaufsicht, Marie-Curie-Straße 24-28, 60439
                  Frankfurt am Main
                </span>
                <br />
                <span>Geschäftsführer: Guy Young and Torsten Luettich</span>
                <br />
                <span>Verantwortlich für journalistisch-redaktionelle Angebote: Torsten Luettich</span>
                <br />
                <span>
                  Die Europäische Kommission stellt unter http://ec.europa.eu/consumers/odr eine Plattform zur
                  Online-Streitbeilegung (OS) bereit. Wir sind nicht verpflichtet und nicht bereit, an einem Verfahren
                  zur Online-Streitbeilegung teilzunehmen.
                </span>
              </p>
            </div>

            <div className="flex flex-wrap gap-4">
              <StyledALink
                url={`${GITBOOK_URL}/resources/terms-of-service`}
                text="Terms of Service"
                classes="text-cblue-500 border-cblue-500 text-xs text-nowrap"
                hideUnderline
              />
              <StyledALink
                text="Terms & Conditions"
                url={`${GITBOOK_URL}/resources/supplemental-usde-terms-and-conditions#qsh70q`}
                classes="text-cblue-500 border-cblue-500 text-xs text-nowrap"
                hideUnderline
              ></StyledALink>
              <StyledALink
                text="Privacy Policy"
                url={`${GITBOOK_URL}/resources/privacy-policy`}
                classes="text-cblue-500 border-cblue-500 text-xs text-nowrap"
                hideUnderline
              ></StyledALink>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
